





















import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";
import { AssetModel } from "@/models/asset";
import AssetFileSelector from "@/components/asset/AssetFileSelector.vue";
import { convertToSlug } from "@/utility/helpers";
@Component({
  components: {
    Icon,
    AssetFileSelector
  }
})
export default class ImagePicker extends Vue {
  @Prop() protected image!: AssetModel;
  @Prop({ default: false }) protected needPath!: boolean;
  protected imagePreview = "";

  protected get cdn(): string {
    return process.env.VUE_APP_CDN_URL;
  }

  protected get imageUrl(): string {
    return (this.cdn +
      this.image.path +
      convertToSlug(this.image.filename) +
      "?width=200") as string;
  }

  protected showFilePicker(): void {
    this.$modal.show(
      AssetFileSelector,
      { header: "Select an Image", image: true },
      {
        adaptive: true,
        height: "100%",
        width: "875px",
        classes: "asset-file-selector"
      }
    );
  }

  protected removeImage(): void {
    this.$emit("removeImage");
    this.imagePreview = "";
  }
}
