




















import { Component, Vue, Prop } from "vue-property-decorator";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import { APIResponse } from "@/models/api_res";
import { ProductModel } from "@/models/product";
import ProductService from "@/services/product_service";
import { AuthError } from "@/services/error_service";
import { EventBus } from "@/events/index";
@Component({
  components: { Autocomplete }
})
export default class RelatedProductModal extends Vue {
  @Prop() id!: number;
  protected data: ProductModel[] = [];
  protected productService = new ProductService();

  protected keyChange(value: any): void {
    this.getProducts({ q: value });
  }
  protected async getProducts(optionsObject?: {
    page?: number;
    offset?: number;
    filter?: string;
    q?: string;
    mfr?: number;
    category?: number;
  }): Promise<void> {
    try {
      const res: APIResponse = await this.productService.getProducts(
        optionsObject
      );
      this.data = res.results.filter((item: ProductModel) => {
        return item.id !== this.id;
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected updateField(item: any) {
    this.$emit("setRelatedProduct", item);
  }
}
