import { helpers } from "vuelidate/lib/validators";

/**
 * Custom validators for use with Vuelidate library
 * @const urlName regex checks if valid url name per API guidelines
 */

export const urlName = helpers.regex(
  "urlName",
  /^[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
);

export const noFwdSlash = helpers.regex("noFwdSlash", /^(?!.*\/).*$/);
