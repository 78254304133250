















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EditTableMenu extends Vue {
  @Prop() rowId!: number;
  @Prop() isEdit!: boolean;

  public editItem(): void {
    this.$emit("toggleEdit", this.rowId);
  }

  public deleteItem(): void {
    this.$emit("deleteValue", this.rowId);
  }
}
