















































































import { Component, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Icon from "@/components/reusable/Icon.vue";
import EditTableMenu from "@/components/reusable/editable_table/EditTableMenu.vue";
import EditTable from "@/components/reusable/editable_table/EditTable.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import { AttributeModel, AttributeRequestOptions } from "@/models/attribute";
import AttributeService from "@/services/attribute_service";
import { APIResponse } from "@/models/api_res";
import { AuthError, AttributeConflictError } from "@/services/error_service";
import { EventBus } from "@/events/index";

@Component({
  components: {
    draggable,
    Icon,
    EditTableMenu,
    FormInput,
    Autocomplete
  }
})
export default class AttrEditTable extends EditTable {
  @Prop({
    default: () => [] as AttributeModel[]
  })
  data!: AttributeModel[];
  @Prop({ default: false }) disableDrag!: boolean;
  protected attrList: AttributeModel[] = [];
  protected attributeService = new AttributeService();

  @Watch("tableData", { deep: true, immediate: false })
  protected onUpdate(): void {
    this.$emit("update", this.tableData);
    if (this.tableData.length < 1) {
      this.addRow();
    }
  }
  mounted() {
    EventBus.$on("checkAttributes", () => this.checkAttrs());

    if (this.data.length < 1) {
      this.tableData = [];
      this.addRow();
    } else {
      this.tableData = [...this.data];
      this.updateEditTags();
    }
  }

  beforeDestroy() {
    EventBus.$off("checkAttributes");
  }

  protected addRow(): void {
    const id = this.randomStringId();
    const newObj: any = { id: id, isEdit: true };
    this.isEditTags.push(newObj);
    const emptyRow: AttributeModel = {
      handle: "",
      display_name: "",
      value: "",
      id: id
    };
    this.tableData.push(emptyRow);
  }

  protected toggleEdit(rowId: number, boolean = true) {
    this.isEditTags.forEach(row => {
      if (row.id === rowId) {
        row.isEdit = boolean;
      }
    });
  }

  /**
   * isEditTags holds an id  & associated boolean which toggles edit on
   * row with matching id.
   *
   * This method iterates through array of objects and builds an object to represent each table row in isEditTags
   * @example ...
   * [{id: 12, isEdit: false}, { id: 22, isEdit: false}]
   */
  protected updateEditTags(): void {
    this.isEditTags = this.tableData.map(row => {
      return { id: row.id, isEdit: false };
    });
  }

  /** Returns whether input is editable */
  public editInput(rowId: number): boolean | null {
    const row: any[] = this.isEditTags.filter(row => {
      if (row.id === rowId) {
        return row; // return matching item in isEditTags (row will = { id: number, isEdit: boolean} )
      }
    });
    if (row[0]) {
      // if row exists, return isEdit value (boolean)
      return row[0].isEdit;
    } else {
      return null; // no match, no change
    }
  }

  protected async updateAttributeInput(item: AttributeModel, rowId: number) {
    const attr = await this.getSingleAttribute(item.id as number); //get attribute from object sent from <Autocomplete> component. this is the item the user clicked on in the dropdown
    this.tableData = this.tableData.map(row => {
      // find matching row and update with data returned from api. do not update row.value, this is user entered
      if (row.id === rowId) {
        row = {
          ...row,
          display_name: attr.display_name,
          handle: attr.handle,
          id: attr.id
        };
      }
      return row;
    });
    this.isEditTags.forEach(item => {
      if (item.id === rowId) {
        item.id = attr.id;
        item.isEdit = true;
      }
    });
  }

  protected async postNewAttr(req: AttributeModel): Promise<AttributeModel> {
    let attr = {} as AttributeModel;
    try {
      const res = await this.attributeService.createNewAttribute(req);
      attr = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof AttributeConflictError) {
        attr = err.details.existing_attr;
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return attr;
  }

  protected async checkAttrs(): Promise<void> {
    for (let i = 0; i < this.tableData.length; i++) {
      if (!this.tableData[i].handle && this.tableData[i].display_name) {
        const res = await this.postNewAttr({
          display_name: this.tableData[i].display_name
        });
        this.tableData[i].id = res.id;
        this.tableData[i].handle = res.handle;
        this.tableData[i].idx = res.idx;
        this.tableData[i].product_count = res.product_count;
      }
    }
    this.tableData = this.tableData.filter(row => row.display_name);
    this.updateEditTags();
    this.$emit("makeRequest");
  }

  protected async getAttributes(
    optionsObject?: AttributeRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.attributeService.getAttributes(
        optionsObject
      );
      this.attrList = res.results;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async getSingleAttribute(id: number): Promise<AttributeModel> {
    let attr = {} as AttributeModel;
    try {
      const res: AttributeModel = await this.attributeService.getSingleAttribute(
        id
      );
      attr = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return attr;
  }

  protected autoCompleteFetchAttr(value: any): void {
    this.getAttributes({ q: value });
  }
}
